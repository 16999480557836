import { Link, useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';

export default function Works() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [isTitleVisible, setIsTitleVisible] = useState(false);
    const containerRef = useRef(null);
    const isScrolling = useRef(false);
    const navigate = useNavigate();
    
    // 이미지 데이터 구조 수정
    const projects = [
        {
            id: 1,
            image: '/works/projects/1.webp',
            title: 'MONGTAN JEJU',
            subTitle:'CEREMONY',
            clients: 'CEREMONY',
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'

        },
        {
            id: 2,
            image: '/works/projects/2.webp',
            title: 'AOMG',
            subTitle:'CEREMONY',
            clients: "MIND-SET",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },
        {
            id: 3,
            image: '/works/projects/3.webp',
            title: 'shuit w',
            subTitle:'CEREMONY',
            clients: "W, W",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
            id: 4,
            image: '/works/projects/4.webp',
            title: 'motungiwoo hightable',
            subTitle:'CEREMONY',
            clients: "SOMMELIER",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
            id: 5,
            image: '/works/projects/5.webp',
            title: 'goonies',
            subTitle:'CEREMONY',
            clients: "NEVER SAY NEVER!",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
            id: 6,
            image: '/works/projects/6.webp',
            title: 'grove',
            subTitle:'CEREMONY',
            clients: "BIOLOGY MUSEUM",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
            id: 7,
            image: '/works/projects/7.webp',
            title: 'salty seoul',
            subTitle:'CEREMONY',
            clients: "FEAST OF WAVES",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
            id: 8,
            image: '/works/projects/8.webp',
            title: 'shuit w',
            subTitle:'CEREMONY',
            clients: "THE PROCESS",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
            id: 9,
            image: '/works/projects/9.webp',
            title: 'N-PIXEL Office',
            subTitle:'CEREMONY',
            clients: "P'OSSIBILITY",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
                id: 10,
            image: '/works/projects/10.webp',
            title: 'PEER',
            subTitle:'CEREMONY',
            clients: "UNEXPECTED",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
            id: 11,
            image: '/works/projects/11.webp',
            title: 'ALAIN DELON',
            subTitle:'CEREMONY',
            clients: "DAYDREAM",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
            id: 12  ,
            image: '/works/projects/12.webp',
            title: 'avecque cheongdam',
            subTitle:'CEREMONY',
            clients: "BLENDING",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
            id: 13,
            image: '/works/projects/13.webp',
            title: 'ADER ERROR',
            subTitle:'CEREMONY',
            clients: "BUT NEAR MISSED WAY",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
            id: 14,
            image: '/works/projects/14.webp',
            title: 'gentlemonster',
            subTitle:'CEREMONY',
            clients: "LAST PIECE",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
            id: 15,
            image: '/works/projects/15.webp',
            title: 'juun.j',
            subTitle:'CEREMONY',
            clients: "DIVERSION OF IMAGINE",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
            id: 16,
            image: '/works/projects/16.webp',
            title: 'light house',
            subTitle:'CEREMONY',
            clients: "NOSTALGIA",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
            id: 17,
            image: '/works/projects/17.webp',
            title: 'chapter 1 collect',
            subTitle:'CEREMONY',
            clients: "WATCH FOR SPACE",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
            id: 18,
            image: '/works/projects/18.webp',
            title: 'endive',
            subTitle:'CEREMONY',
            clients: "1,0,ㅇ,ㅣ",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
            id: 19,
            image: '/works/projects/19.webp',
            title: 'boskett',
            subTitle:'CEREMONY',
            clients: "THE LAB",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },{
            id: 20,
            image: '/works/projects/20.webp',
            title: 'chapter 1 select',
            subTitle:'CEREMONY',
            clients: "UNFAMILIAR BEAUTY",
            usage:'"BARBECUE RESTAURANT',
            siteaArea:'329.8M2/100PY',
            location:'GANGNAM-GU,SEOUL',
            date:'AUG, 2023'
        },
        
    ];

    // 타이틀 표시 효과를 위한 useEffect
    useEffect(() => {
        if (hoveredIndex !== null) {
            // 약간의 딜레이 후 타이틀 표시
            setTimeout(() => {
                setIsTitleVisible(true);
            }, 50);
        } else {
            setIsTitleVisible(false);
        }
    }, [hoveredIndex]);

    const handleProjectClick = (projectId, e) => {
        // 스크롤 동작과 클릭 동작을 구분하기 위한 처리
        if (!isScrolling.current) {
            navigate(`/works/project/${projectId}`);
        }
    };

    const scrollToImage = (index) => {
        if (isScrolling.current) return;
        
        isScrolling.current = true;
        setCurrentIndex(index);
        
        const container = containerRef.current;
        if (container) {
            container.children[index].scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
            
            setTimeout(() => {
                isScrolling.current = false;
            }, 250);
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        let lastScrollTime = 0;
        const scrollThreshold = 1000;

        const handleWheel = (e) => {
            e.preventDefault();
            
            const currentTime = new Date().getTime();
            if (currentTime - lastScrollTime < scrollThreshold || isScrolling.current) {
                return;
            }

            const direction = e.deltaY > 0 ? 1 : -1;
            const nextIndex = Math.min(Math.max(currentIndex + direction, 0), projects.length - 1);
            
            if (nextIndex !== currentIndex) {
                lastScrollTime = currentTime;
                scrollToImage(nextIndex);
            }
        };

        container.addEventListener('wheel', handleWheel, { passive: false });
        
        return () => {
            container.removeEventListener('wheel', handleWheel);
        };
    }, [currentIndex, projects.length]);

    return (
        <div className="bg-black w-screen h-screen">
            {/* 헤더 영역 */}
            <div className="fixed top-0 left-0 w-full h-32 z-20  flex items-center justify-between px-10">
                <div><img src="/home/logo.png" alt="logo" className="w-full" /></div>
                <div className="flex justify-center items-center gap-20">
                    <Link to="/works" className="text-white text-md cursor-pointer">Works</Link>
                    <Link to="/about" className="text-white text-md cursor-pointer">About</Link>
                    <Link to="/contact" className="text-white text-md cursor-pointer">Contact</Link>
                </div>
            </div>

            {/* Dot 인디케이터 수정 */}
            <div className="fixed right-8 top-40 z-40 flex flex-col gap-3">
                {projects.map((project, index) => (
                    <button
                        key={index}
                        onClick={() => scrollToImage(index)}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        className={`w-2 h-2 rounded-full transition-all duration-500 ease-in-out ${
                            currentIndex === index 
                                ? 'bg-white scale-125' 
                                : 'bg-gray-500 hover:bg-white'
                        }`}
                        aria-label={`Go to image ${index + 1}`}
                    />
                ))}
            </div>

            {/* 이미지 리스트 */}
            <div 
                ref={containerRef} 
                className="w-full snap-y snap-mandatory overflow-y-auto scroll-smooth scrollbar-hide pt-32"
                style={{
                    scrollBehavior: 'smooth',
                    WebkitOverflowScrolling: 'touch',
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none'
                }}
            >
                {projects.map((project, index) => (
                    <div 
                        key={project.id} 
                        className="w-full snap-start relative flex items-start justify-center"
                        onClick={(e) => handleProjectClick(project.id, e)}
                    >
                        <img 
                            src={project.image}
                            alt={project.title}
                            className="w-full h-auto object-contain cursor-pointer"
                        />
                    </div>
                ))}
            </div>

            {/* 호버 시 타이틀 오버레이 수정 */}
            {hoveredIndex !== null && (
                <>
                    {/* 배경 딤 처리 - 더 빠른 페이드인 */}
                    <div 
                        className="fixed inset-0 bg-black z-30 transition-opacity duration-300 ease-in-out"
                        style={{
                            animation: 'dimFadeIn 0.3s ease-in-out forwards'
                        }}
                    />
                    
                    {/* 텍스트 - 더 느린 페이드인 */}
                    <div 
                        className="fixed bottom-10 left-10 flex flex-col gap-1 z-30 transition-opacity duration-500 ease-in-out"
                        style={{
                            animation: 'textFadeIn 0.5s ease-in-out forwards'
                        }}
                    >
                        <h2 className="text-white text-3xl font-light opacity-80">
                            {projects[hoveredIndex].title}
                        </h2>
                        <p className="text-white text-sm font-extralight opacity-70">
                            {projects[hoveredIndex].subTitle}
                        </p>
                    </div>
                </>
            )}

            <style jsx>{`
                @keyframes dimFadeIn {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 0.4;
                    }
                }

                @keyframes textFadeIn {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }
            `}</style>
        </div>
    );
}