import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function ProjectDetail() {
    const { id } = useParams();
    const [project, setProject] = useState({
        id: 1,
        image: '/works/projects/1.webp',
        title: 'MONGTAN JEJU',
        subtitle: 'CEREMONY',
        client: 'MT FOOD',
        usage: 'BARBECUE RESTAURANT',
        siteArea: '329.8M2/100PY',
        scope: 'CONCEPT DESIGN, CONSTRUCTION, BRANDING',
        location: 'GANGNAM-GU, SEOUL',
        date: 'AUG, 2023',
        illustration: '/works/projects/object/1.png'
    });

    return (
        <div className="bg-black w-screen h-screen text-[#e2e2e2]">
            {/* 헤더 영역 */}
            <div className="fixed top-0 left-0 w-full h-32 z-30 bg-black flex items-center justify-between px-10">
                <div><img src="/home/logo.png" alt="logo" className="w-full" /></div>
                <div className="flex justify-center items-center gap-20">
                    <Link to="/works" className="text-[#e2e2e2] text-md cursor-pointer">Works</Link>
                    <Link to="/about" className="text-[#e2e2e2] text-md cursor-pointer">About</Link>
                    <Link to="/contact" className="text-[#e2e2e2] text-md cursor-pointer">Contact</Link>
                </div>
            </div>

            {/* 우측 하단 일러스트레이션 - 뒤쪽 레이어 */}
            <div className="fixed flex justify-end items-center bottom-0  right-0 z-10 px-24 ">
                <img 
                    src={project.illustration} 
                    alt="Project Illustration" 
                    className="max-w-[100%] h-auto opacity-90"
                />
            </div>

            {/* 컨텐츠 영역 - 앞쪽 레이어 */}
            <div className="relative flex items-center w-full min-h-screen pb-24 px-10 z-20">
                {/* 왼쪽 텍스트 정보 */}
                <div className="flex flex-col   gap-8 max-w-2xl">
                    {/* 타이틀 섹션 */}
                    <div className="flex flex-col ">
                        <h1 className="text-2xl font-light">{project.title}</h1>
                        <h2 className="text-xl font-light text-[#7e7e7e]">{project.subtitle}</h2>
                    </div>

                    {/* 프로젝트 정보 리스트 */}
                    <div className="flex flex-col  text-xl font-light">
                        <div className="flex gap-2">
                            <span className="opacity-60 ">CLIENT :</span>
                            <span>{project.client}</span>
                        </div>
                        <div className="flex gap-2">
                            <span className="opacity-60 ">USAGE :</span>
                            <span>{project.usage}</span>
                        </div>
                        <div className="flex gap-2">
                            <span className="opacity-60 ">SITE AREA :</span>
                            <span>{project.siteArea}</span>
                        </div>
                        <div className="flex gap-2">
                            <span className="opacity-60 ">SCOPE :</span>
                            <span>{project.scope}</span>
                        </div>
                        <div className="flex gap-2">
                            <span className="opacity-60 ">LOCATION :</span>
                            <span>{project.location}</span>
                        </div>
                        <div className="flex gap-2">
                            <span className="opacity-60 ">DATE :</span>
                            <span>{project.date}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
